import constCondition from '~/const/searchCondition.js'
import constCustomer from '~/const/customer';
import _ from "lodash";

export default {
  computed: {
    queryParamKeyword() {
      return _.replace(_.get(this.$route.query, 'keywords'), /,/g, ' ');
    },
  },
  methods: {
    // 検索条件からクエリパラメータ生成
    $_searchConditionsMixin_createQueryParams(searchConditions, page, order) {
      const queryParams = {};
      Object.keys(searchConditions).forEach((index) => {
        let conditions = searchConditions[index];
        //null許可数値の場合
        if (constCondition.conditionAcceptNull.includes(index)) {
          this.$set(queryParams, index, conditions);
        }

        if (conditions === null
          || conditions === undefined
          || conditions.length === 0
        ) return;

        // 配列の場合
        if (constCondition.conditionArrayString.includes(index)
          || constCondition.conditionArrayInt.includes(index)
          || constCondition.conditionMedia.includes(index)) {
          const connect = [];
          Object.keys(conditions).forEach((value_index) => {
            connect.push(conditions[value_index]);
          });
          this.$set(queryParams, index, connect.join(','));
        }
        // 文字列、数値、日付、真偽の場合
        if (constCondition.conditionString.includes(index)
          || constCondition.conditionInt.includes(index)
          || constCondition.conditionDate.includes(index)
          || constCondition.conditionBool.includes(index)) {
          this.$set(queryParams, index, conditions);
        }
        //スペースあり文字列の場合
        if (constCondition.conditionStrings.includes(index)) {
          this.$set(queryParams, index, conditions.join(','));
        }
        //メール開封、リンクトラッキング
        if(constCondition.conditionIntDaysTimes.includes(index)) {
          this.$set(queryParams,index, String(conditions.days).valueOf() + ',' + String(conditions.times).valueOf());
        }
        if(index === 'location'){
          Object.keys(conditions).forEach((locationIndex) => {
            if (constCondition.conditionLocationInt.includes(locationIndex) && conditions[locationIndex]){
              this.$set(queryParams, locationIndex, conditions[locationIndex]);
            }
            if(constCondition.conditionLocationArrayInt.includes(locationIndex) && conditions[locationIndex]) {
              this.$set(queryParams, locationIndex, conditions[locationIndex].join(','));
            }
          });
        }
      });

      if (order) {
        this.$set(queryParams, 'target', order.target);
        this.$set(queryParams, 'isAsc', order.isAsc);
      }

      const routeOptions = _.isEmpty(page)
        ? { path: "/", query: queryParams }
        : !isNaN(page)
        ? { path: String(page), query: queryParams }
        : { name: page, query: queryParams };

      this.$router.push(routeOptions);
    },

    // URLのクエリパラメータから検索条件生成
    $_searchConditionsMixin_createSearchConditions(queryParams) {
      if (!Object.keys(queryParams).length) return;

      const order = {};
      const createdSearchConditions = {};
      const createdSearchConditionsLocation = {} ;
      Object.keys(queryParams).forEach((index) => {
        let condition;
        let param = queryParams[index];
        let isLocation = false;

        //値が文字列の配列の場合
        if (constCondition.conditionArrayString.includes(index)) {
          condition = param.split(',').map(value => value.toString());
        }
        //値が数値の配列の場合
        if (constCondition.conditionArrayInt.includes(index)) {
          condition = param.split(',').map(value => parseInt(value, 10)), item => !_.isEmpty(item);
        }
        //文字列の場合
        if (constCondition.conditionString.includes(index)) {
          condition = param.toString();
        }
        //スペースあり文字列の場合
        if (constCondition.conditionStrings.includes(index)) {
          index = 'keyword';
          if (_.isEmpty(param)) {
            condition = null;
          } else {
            condition = param.replace(/,/g, ' ');
          }
        }
        //数値の場合
        if (constCondition.conditionInt.includes(index)) {
          if (!_.isEmpty(param)) {
            condition = parseInt(param, 10);
          } else {
            if (index === 'mainAdminId' || index === 'subAdminId') {
              condition = constCustomer.defaultStaff;
            }
          }
        }
        if (constCondition.conditionIntDaysTimes.includes(index.replace('Days',''))) {

          condition = {
            days: parseInt(param.split(',')[0],10)  ?? null,
            times: parseInt(param.split(',')[1],10) ?? null,
          }
        }

        //日付の場合
        if (constCondition.conditionDate.includes(index)) {
          condition = param;
        }
        //真偽の場合
        if (constCondition.conditionBool.includes(index)) {
          condition = param === 'true' ?? false;
        }
        //null許可数値の場合
        if (constCondition.conditionAcceptNull.includes(index)) {
          condition = param;
        }
        //constMediaの場合
        if (constCondition.conditionMedia.includes(index)) {
          condition = param.split(',').map(value => value.toString());
          if (!createdSearchConditions.media) this.$set(createdSearchConditions, 'media', {});
          this.$set(createdSearchConditions.media, index, condition);
        }
        //ソートの場合
        if (constCondition.conditionSort.includes(index)) {
          this.$set(order, index, param);
        }
        //エリア(都道府県、市区町村):数値
        if (constCondition.conditionLocationInt.includes(index)){
          if (!_.isEmpty(param)) {
            condition = param.toString();
            isLocation = true;
          }
        }
        //エリア(町域、小学校区、中学校区、沿線、駅):数値の配列
        if(constCondition.conditionLocationArrayInt.includes(index)){
          condition = param.split(',').map(value => value.toString());
          isLocation = true;
        }
        //portalMemberまたはshopMemberの場合
        if (index === 'portalMember' && param === '1') this.searchConditions.memberRegistered[0] = true;
        if (index === 'shopMember' && param === '1') this.searchConditions.memberRegistered[1] = true;
        if(isLocation){
          this.$set(createdSearchConditionsLocation, index, condition);
        }else{
          this.$set(createdSearchConditions, index, condition);
        }
      });
      this.$set(createdSearchConditions, 'location', createdSearchConditionsLocation);
      return Object.assign({}, {
        'searchConditions': createdSearchConditions,
        'order': order,
      });
    },
    
    //format location
    $_searchConditionsMixin_formatLocation(location) {
      if (!location) return null;
      let locationFormat = { ...location };

      [
        { item: 'tyugakkous', property: 'surrounding' },
        { item: 'shogakkous', property: 'surrounding' },
        { item: 'ekiIds', property: 'eki_id' },
        { item: 'cyoCds', property: 'cyo_cd' },
        { item: 'ensenIds', property: 'ensen_id' },
      ].forEach(({ item, property }) => {
        const array = locationFormat[item];
        if (!_.isEmpty(array)) {
          locationFormat[item] = array.filter(i => !!i).map(i => i[property])
        }
      })
      return locationFormat;
    },
  }
}

